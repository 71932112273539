import { AuthState } from '@/types/authTypes';
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState: AuthState = {
  user: null,
  token: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      Cookies.remove('token');
    }
  }
});

export const { setUser, logout, setToken } = authSlice.actions;

export default authSlice.reducer;
